import styled from 'styled-components';

import { font, colors, z, bz } from '../consts/style';
import mq from '../style/mq';
import { fadeInUp } from '../style/animations';

export const PageWrapper = styled.div`
  min-height: calc(100vh - 48.5rem);
`;

export const Container = styled.div`
  padding: 0 2.4rem;
  height: 100%;
`;

export const Inner = styled.div`
  width: 118rem;
  max-width: 100%;
  margin: auto;
`;

export const DemoWrapper = styled.div`
  background: ${colors.yellow};
  ${Inner} {
    display: flex;
    align-items: center;
    padding: 8rem 0;
    ${mq.tablet`
      flex-wrap: wrap;
      padding: 4rem 0;
    `}
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  margin-right: 4rem;
  flex-shrink: 1;
  ${mq.tablet`
    order: 1;
    margin-right: 0;
  `}
`;
export const VideoText = styled.div`
  h2 {
    ${font.heading};
    color: ${colors.dark};
  }
  width: 36rem;
  ${mq.tablet`
    width: 100%;
    order: 0;
    margin-bottom: 2rem;
  `}
`;

export const SectionHeading = styled.h1`
  ${font.heading};
  text-align: center;
  color: white;
  margin-bottom: 8rem;
`;
