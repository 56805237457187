import React from 'react';
import styled from 'styled-components';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Reboot from '../style/reboot';
import Global from '../style/global';
import { colors } from '../consts/style';
import { layoutTypes } from '../types/propTypes';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const SiteWrapper = styled.div`
  background: ${colors.blue};
`;

const Layout = ({ children, location }) => {
  return (
    <SiteWrapper>
      <Reboot />
      <Global />
      <Header location={location} />
      {children}
      <Footer location={location} />
      <ToastContainer />
    </SiteWrapper>
  );
};

Layout.propTypes = layoutTypes;

export default Layout;
