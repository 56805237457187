import { keyframes } from 'styled-components';

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const fadeInUp = keyframes`
  from {
    top: 6rem;
    opacity: .25;
  }

  to {
    top: 0;
    opacity: 1;
  }
`;

export const float = keyframes`
	0% {
		transform: translatey(0rem);
	}
	50% {
		transform: translatey(-1.2rem);
	}
	100% {
		transform: translatey(0rem);
	}
`;
