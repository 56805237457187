import React, { useEffect, useState, Fragment } from 'react';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import styled from 'styled-components';

import { colors, z, font, bz, boxShadow } from '../consts/style';
import { headerTypes } from '../types/propTypes';
import mq from '../style/mq';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  height: 5rem;
  ${mq.mobile`
  padding: 3rem;
  height: 4rem;
  `}
  ${z.header}
  img {
    height: 3.2rem;
    width: auto;
    ${mq.mobile`
      height: 1.6rem;
      width: auto;
    `}
  }
  button {
    width: 4rem;
    height: 3rem;
    position: relative;
    background: transparent;
    border: none;
    padding: 0;
    ${mq.mobile`
    width: 3.2rem;
    height: 2rem;
    div {
      height: .4rem !important;
      &:nth-child(2n) {
        top: calc(50% - 0.2rem) !important;
      }
    }
    `}
    div {
      position: absolute;
      width: 100%;
      left: 0;
      height: 0.6rem;
      background: white;
      &:first-child {
        top: 0;
      }
      &:last-child {
        bottom: 0;
      }
      &:nth-child(2n) {
        top: calc(50% - 0.3rem);
      }
    }
  }
  ${mq.tabletSmall`
    padding: 2.8rem 2rem;
  `}
`;

const MobileMenu = styled.nav`
  position: fixed;
  right: 0;
  height: 100%;
  width: 36rem;
  padding: 2.4rem;
  background: ${colors.dark};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  transform: translateX(100%);
  transition: 0.4s all;
  ${boxShadow.default};
  ${bz};
  ${z.menu};
  ${mq.mobile`
    width: 100%;
  `}
  &.open {
    transform: translateX(0%);
  }
  .menu-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      max-height: 3rem;
      width: auto;
    }
    button {
      width: 3rem;
      height: 2rem;
      position: relative;
      background: transparent;
      border: none;
      padding: 0;
      div {
        position: absolute;
        width: 100%;
        left: 0;
        height: 0.6rem;
        background: white;
        top: calc(50% - 0.3rem);
        &:first-child {
          transform: rotate(45deg);
        }
        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .menu-items {
    margin-top: 12vh;
    display: flex;
    flex-direction: column;
    a,
    button {
      text-align: left;
      border: 0;
      line-height: 100%;
      margin-bottom: 1.6rem;
      color: white;
      text-decoration: none;
      ${font.menu};
      &[aria-current='page'] {
        color: ${colors.yellow};
      }
    }
    button {
      background: ${colors.yellow};
      color: ${colors.dark};
      padding: 0.6rem 1.2rem;
      border-radius: 2.1rem;
      span {
        position: relative;
        top: 0.3rem;
      }
    }
  }
  .menu-bottom {
    a {
      margin-right: 1.2rem;
    }
  }
`;
const CTA = styled.a`
  position: fixed;
  top: 0;
  right: calc(10% + 12rem);
  ${z.cta};
  background: ${colors.yellow};
  transition: 0.4s all;
  transform: translateY(${props => (props.menuOpen ? '-100%' : '0%')});
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 0.4rem 1.2rem 0;
  overflow: hidden;
  ${boxShadow.default};
  ${bz};
  ${mq.tablet`
    right: calc(15% + 12rem);
  `}
  ${mq.tabletSmall`
    display: none;
  `}
  button {
    ${font.button};
    font-size: 3.2rem;
    background: ${colors.yellow};
    color: ${colors.blue};
  }
`;

const CTA2 = styled(CTA)`
  right: 10%;
  background: ${colors.teal};
  button {
    background: ${colors.teal};
  }

  ${mq.tablet`
    right: 15%;
  `}
`;

const headerQuery = graphql`
  {
    datoCmsMisc {
      logo {
        url
      }
      facebook
      facebookIcon {
        url
      }
      linkedin
      linkedinIcon {
        url
      }
      twitter
      twitterIcon {
        url
      }
    }
  }
`;

export default function Header({ location }) {
  const data = useStaticQuery(headerQuery);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => setMenuOpen(false), [location]);

  const isSignUp = location.pathname.includes('sign-up');

  function handleCtaClick() {
    navigate('/sign-up/');
  }

  return (
    <Fragment>
      <Wrapper>
        <Link to="/">
          <img src={data.datoCmsMisc.logo.url} alt="Logo" />
        </Link>
        <button
          aria-label="Open menu"
          aria-pressed={menuOpen}
          onClick={() => setMenuOpen(true)}
        >
          <div />
          <div />
          <div />
        </button>
      </Wrapper>
      <MobileMenu className={menuOpen && 'open'}>
        <div className="menu-top">
          <div className="menu-heading">
            <img src={data.datoCmsMisc.logo.url} alt="Logo" />
            <button
              aria-label="Close menu"
              aria-pressed={!menuOpen}
              onClick={() => setMenuOpen(false)}
            >
              <div />
              <div />
            </button>
          </div>
          <div className="menu-items">
            <Link to="/">Home</Link>
            <Link to="/features/">Features</Link>
            <Link to="/for-explorers/">Exploration</Link>
            <Link to="/for-drillers/">Drilling</Link>
            <Link to="/faq/">FAQ</Link>
            <a target="blank" href="https://we.drillwithlantern.com/login">
              Log In
            </a>
            <div>
              <button onClick={handleCtaClick}>
                <span>Get Lantern</span>
              </button>
            </div>
          </div>
        </div>
        <div className="menu-bottom">
          {data.datoCmsMisc.linkedin && (
            <a href={data.datoCmsMisc.linkedin} target="blank">
              <img
                src={data.datoCmsMisc.linkedinIcon.url}
                alt="Linkedin icon"
              />
            </a>
          )}
          {data.datoCmsMisc.facebook && (
            <a href={data.datoCmsMisc.facebook} target="blank">
              <img
                src={data.datoCmsMisc.facebookIcon.url}
                alt="Facebook icon"
              />
            </a>
          )}
          {data.datoCmsMisc.twitter && (
            <a href={data.datoCmsMisc.twitter} target="blank">
              <img src={data.datoCmsMisc.twitterIcon.url} alt="Twitter icon" />
            </a>
          )}
        </div>
      </MobileMenu>

      <CTA
        id="Setmore_button_iframe"
        href="https://my.setmore.com/bookingpage/8a305d39-5ae5-4dfd-9dda-0df93ae34d54"
        menuOpen={menuOpen}
      >
        <button>Book a demo</button>
      </CTA>

      <CTA2
        menuOpen={menuOpen}
        href="https://we.drillwithlantern.com/login"
        target="blank"
      >
        <button>Log In</button>
      </CTA2>
    </Fragment>
  );
}

Header.propTypes = headerTypes;
