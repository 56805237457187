/* eslint-disable no-console */
import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors, font } from '../consts/style';
import mq from '../style/mq';
import { Container, Inner } from './Elements';
import { SUBSCRIBE } from '../consts/endpoints';

export const Wrapper = styled.div``;

const Top = styled.div`
  overflow: hidden;
  background: ${colors.greyLight};
  border-bottom: 0.8rem solid ${colors.yellow};
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  .image {
    margin-bottom: 2rem;
  }
  .title {
    margin-bottom: 1rem;
    h1 {
      ${font.h2};
      color: ${colors.dark};
      position: relative;
      top: 0.8rem;
      ${mq.tabletSmall`
        top: 0.6rem;
      `}
    }
  }
  .subscribe {
    width: 36rem;
    max-width: 100%;
    margin: 2rem auto;
    position: relative;

    form {
      display: flex;
      transition: opacity 0.3s ease;
      outline: none;

      &.loading {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }
    }

    input {
      width: 100%;
      background: rgba(255, 255, 255, 0.25);
      border: none;
      border-radius: 100px 0 0 100px;
      padding-left: 1.2rem;
      color: ${colors.dark};
      outline: none;
      &::placeholder {
        color: ${colors.dark};
      }
    }

    button {
      ${font.button}
      color: white;
      background: ${colors.dark};
      padding: 1.2rem;
      padding-bottom: 1rem;
      border-radius: 0 100px 100px 0;
    }
  }
  .message {
    margin-bottom: 4rem;
    p {
      ${font.pLarge};
      font-size: 2.2rem;
      color: ${colors.dark};
      margin-bottom: 4rem;
      ${mq.tabletSmall`
          font-size: 1.6rem;
          `}
    }
    a {
      ${font.button};
      background: ${colors.yellow};
      color: ${colors.blue};
      border-radius: 12rem;
      padding: 0.4rem 2.4rem 0.2rem;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
        background: ${colors.teal};
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
const Bottom = styled.div`
  background: ${colors.dark};
  padding: 4rem 0;

  ${Inner} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    .meta {
      ${mq.tabletSmall`
          width: 100%;
        `}
      .social {
        text-align: right;
        a {
          margin-left: 1.2rem;
        }
      }

      p {
        margin-top: 1.2rem;
        margin-bottom: 0;
        color: white;
        font-size: 2rem;
        text-align: right;
        &:last-child {
          margin-top: 0;
        }
        a {
          font-size: 1.6rem;
          color: white;
        }
        ${mq.tabletSmall`
          font-size: 1.6rem;
        `}
      }
    }
    .menu {
      width: 26rem;
      display: flex;
      flex-direction: column;

      ${mq.tabletSmall`
          width: 100%;
          margin-bottom: 2rem;
        `}

      .menu-links {
        display: flex;
        .menu-links-col {
          margin-right: 2rem;
          display: flex;
          flex-direction: column;
        }
      }
      img {
        width: 20rem;
        height: auto;
        margin-bottom: 1.2rem;
      }

      a {
        font-size: 2rem;
        color: white;
        text-decoration: none;
        margin-top: 0.4rem;

        &[aria-current='page'] {
          color: ${colors.yellow};
        }
        ${mq.tabletSmall`
          font-size: 1.6rem;
        `}
      }
    }
  }
`;

const footerQuery = graphql`
  {
    datoCmsMisc {
      logo {
        url
      }
      icon {
        url
      }
      facebook
      facebookIcon {
        url
      }
      linkedin
      linkedinIcon {
        url
      }
      twitter
      twitterIcon {
        url
      }
    }
  }
`;

Footer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default function Footer({ location }) {
  const { datoCmsMisc } = useStaticQuery(footerQuery);
  const [Email, setEmail] = useState('');
  const [loading, setLoading] = useState('');

  const d = new Date();
  const year = d.getFullYear();
  const isSignUp = location.pathname.includes('sign-up');

  function onSubmitSubscribe(e) {
    e.preventDefault();
    if (loading) return null;

    const body = JSON.stringify({
      Email,
    });

    setLoading(true);

    fetch(SUBSCRIBE, {
      method: 'post',
      body,
    })
      .then(res => res.json())
      .then(data => handleSuccess(data))
      .catch(() => {
        alert('Oops! Something went wrong.');
      });
  }

  function handleSuccess(data) {
    console.log(data);
    setEmail('');
    setLoading(false);
  }

  return (
    <Wrapper id="footer">
      {!isSignUp && (
        <Top>
          <Container>
            <Inner>
              <div className="image">
                <img src={datoCmsMisc.icon.url} alt="Logo icon" />
              </div>
              <div className="title">
                <h1>Get Started For Free</h1>
              </div>
              <div className="message">
                <p>
                  Take a tour of the platform or dive in right away and start
                  drilling with Lantern.
                </p>
                <a
                  id="Setmore_button_iframe"
                  href="https://my.setmore.com/bookingpage/8a305d39-5ae5-4dfd-9dda-0df93ae34d54"
                >
                  Book a Demo
                </a>
                <Link to="/sign-up/">Get Lantern</Link>
              </div>
              <div className="subscribe">
                <form
                  onSubmit={onSubmitSubscribe}
                  className={loading ? 'loading' : undefined}
                >
                  <input
                    id="subscribe-email"
                    type="email"
                    placeholder="Sign up for News"
                    required
                    onChange={e => setEmail(e.target.value)}
                    value={Email}
                  />
                  <button type="submit">Submit</button>
                </form>
              </div>
            </Inner>
          </Container>
        </Top>
      )}
      <Bottom>
        <Container>
          <Inner>
            <div className="menu">
              <img src={datoCmsMisc.logo.url} alt="Logo" />
              <div className="menu-links">
                <div className="menu-links-col">
                  <Link to="/">Home</Link>
                  <Link to="/features/">Features</Link>
                  <Link to="/for-explorers/">Exploration</Link>
                </div>
                <div className="menu-links-col">
                  <Link to="/for-drillers/">Drilling</Link>
                  <Link to="/faq/">FAQ</Link>
                  <Link to="/sign-up/">Get Lantern</Link>
                </div>
              </div>
            </div>
            <div className="meta">
              <div className="social">
                {datoCmsMisc.linkedin && (
                  <a href={datoCmsMisc.linkedin} target="blank">
                    <img
                      src={datoCmsMisc.linkedinIcon.url}
                      alt="Linkedin icon"
                    />
                  </a>
                )}
                {datoCmsMisc.facebook && (
                  <a href={datoCmsMisc.facebook} target="blank">
                    <img
                      src={datoCmsMisc.facebookIcon.url}
                      alt="Facebook icon"
                    />
                  </a>
                )}
                {datoCmsMisc.twitter && (
                  <a href={datoCmsMisc.twitter} target="blank">
                    <img src={datoCmsMisc.twitterIcon.url} alt="Twitter icon" />
                  </a>
                )}
              </div>
              <p>&copy; {year} Lantern Software</p>
              <p>
                <a
                  target="blank"
                  href="https://learn.drillwithlantern.com/privacy-policy"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </Inner>
        </Container>
      </Bottom>
    </Wrapper>
  );
}
