import PropTypes from 'prop-types';

export const seoTypes = {
  meta: PropTypes.object.isRequired,
};
export const layoutTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};
export const headerTypes = {
  location: PropTypes.object.isRequired,
};
export const pageHeroTypes = {
  bg: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  gif: PropTypes.string.isRequired,
  headingColor: PropTypes.string,
  bodyColor: PropTypes.string,
};
export const genericHeroTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  imageMobile: PropTypes.object.isRequired,
};
export const featureBlockTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};
export const genericBlockTypes = {
  bg: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string,
  gif: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
};
export const infoBlocksTypes = {
  highlightColor: PropTypes.string.isRequired,
  blocks: PropTypes.array.isRequired,
};
export const videoTypes = {
  id: PropTypes.string.isRequired,
};
export const featureTypes = {
  features: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
export const pricingTypes = {
  pricing: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
export const section1Types = {
  gif: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export const homeHeroTypes = {
  image: PropTypes.object.isRequired,
  imageMobile: PropTypes.object.isRequired,
  webm: PropTypes.string.isRequired,
  webmMobile: PropTypes.string.isRequired,
  mp4: PropTypes.string.isRequired,
  mp4Mobile: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  posterMobile: PropTypes.string.isRequired,
};

export const blogListingTypes = {
  blogsTitle: PropTypes.string.isRequired,
  blogPosts: PropTypes.array.isRequired,
};

export const blogCardProps = {
  post: PropTypes.object.isRequired,
  highlightColor: PropTypes.string.isRequired,
};

export const iconTypes = {
  color: PropTypes.string.isRequired,
};
