import 'typeface-teko';
import 'typeface-ubuntu';
import { createGlobalStyle } from 'styled-components';
import { colors, font } from '../consts/style';

const Global = createGlobalStyle`
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  outline: none !important;
}

/**
* Overrides
*/

.Toastify__toast--info {
  background: ${colors.teal};
  text-align: center;
  ${font.h4}
}
.Toastify__toast--warning {
  background: ${colors.pink};
  text-align: center;
  ${font.h4}
}
`;

export default Global;
