/* eslint-disable quotes */
import bp from './breakpoints';

const mq = {
  mobile: `${bp.mobile}px`,
  tabletSmall: `${bp.tabletSmall}px`,
  tablet: `${bp.tablet}px`,
  desktop: `${bp.desktop}px`,
};

export const bz = `
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  `;

export const font = {
  menu: `
        font-size: 3rem;
        font-family: "Teko", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
    `,
  h1: `
    font-size: 7.5rem;
    font-family: "Teko", sens-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 84%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 4rem;
    }
  `,
  heading: `
    font-size: 5rem;
    font-family: "Teko", sens-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 84%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 4rem;
    }
  `,
  h2: `
    font-size: 5rem;
    font-family: "Teko", sens-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 84%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 3.2rem;
    }
  `,
  h3: `
    font-size: 4rem;
    font-family: "Teko", sens-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 84%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 2.4rem;
    }
  `,
  h4: `
    font-size: 3rem;
    font-family: "Teko", sens-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 84%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 2rem;
    }
  `,
  p: `
    font-size: 1.4rem;
    font-family: "Ubuntu", sens-serif;
    line-height: 150%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 1.2rem;
    }
  `,
  featuredIn: `
    font-size: 1.5rem;
    font-family: "Ubuntu", sens-serif;
    line-height: 150%;
    font-weight: bold;
    text-transform: uppercase;
  `,
  pLarge: `
    font-size: 2.4rem;
    font-family: "Ubuntu", sens-serif;
    line-height: 150%;
    @media screen and (max-width: ${mq.tabletSmall}) {
      font-size: 1.6rem;
    }
  `,
  button: `
    font-size: 2.4rem;
    font-family: "Teko", sens-serif;
    line-height: 100%;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 84%;
    padding: .6rem 1rem;
    border: none;
    span {
      position: relative;
      top: .1rem;
    }
  `,
};

export const colors = {
  dark: '#272C3B',
  blue: '#1e2333',
  light: '#fafafa',
  purple: '#9135AD',
  yellow: '#FFC600',
  teal: '#65C9F4',
  tealDriller: '#0ea4b9',
  grey: '#414651',
  greyLight: '#9C9FA7',
  opaque: '#FFFFFF6A',
  pink: '#E23A56',
  orange: '#df8107',
};

export const boxShadow = {
  default: 'box-shadow: 0px 3px 6px #00000029;',
};

export const gradients = {
  default:
    'background: transparent linear-gradient(180deg, #0B0C0F 0%, #333D5A 32%, #1C243C 100%) 0% 0% no-repeat padding-box;',
};

export const z = {
  header: 'z-index: 100000;',
  menu: 'z-index: 200000;',
  cta: 'z-index: 300000;',
};

export const duration = 500;
